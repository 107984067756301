import { sortBy } from "lodash-es"

import {
  MenuCategory
} from "@/model/MenuCategory"

/* eslint-disable camelcase */
export interface MenuDish {
  id: string
  menu_category: MenuCategory
  line_1: string
  line_2?: string | null
  price?: number | null
  sort?: number | null
  date_created: string
}
/* eslint-disable camelcase */

export type MenuDishesDicttionary = {
  [key: string]: MenuDish[] | undefined
}

type Sortable = Pick<MenuDish, "sort" | "date_created">

export function sortDishes<T extends Sortable>(ds: T[]): T[] {
  return sortBy(ds, ["sort", "date_created"])
}
