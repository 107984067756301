



























import Vue, { PropType } from "vue"

import MenuDishComponent from "@/components/MenuDish.vue"

import { MenuCategory } from "@/model/MenuCategory"
import {
  MenuDish
} from "@/model/MenuDish"

export default Vue.extend({
  components: {
    MenuDishComponent
  },

  props: {
    category: {
      type: Object as PropType<MenuCategory>,
      required: true
    },
    dishes: {
      type: Array as PropType<MenuDish[]>,
      default: () => []
    }
  }
})
