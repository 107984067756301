import { sortBy } from "lodash-es"

/* eslint-disable camelcase */
export interface MenuCategory {
  id: string
  name: string
  description?: string | null
  sort?: number | null
  date_created: string
}
/* eslint-enable camelcase */

type Sortable = Pick<MenuCategory, "sort" | "date_created">

export function sortCategories<T extends Sortable>(cs: T[]): T[] {
  return sortBy(cs, ["sort", "date_created"])
}
