








import Vue from "vue"

import FoodMenu from "@/components/FoodMenu.vue"

export default Vue.extend({
  components: {
    FoodMenu
  }
})
