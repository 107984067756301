






























import Vue, { PropType } from "vue"

import BasePrice from "@/components/BasePrice.vue"
import { MenuDish } from "@/model/MenuDish"

export default Vue.extend({
  components: {
    BasePrice
  },

  props: {
    dish: {
      type: Object as PropType<MenuDish>,
      required: true
    }
  }
})
