




































import {
  groupBy,
  mapValues
} from "lodash-es"
import Vue from "vue"

import BaseErrorText from "@/components/BaseErrorText.vue"
import BaseLoading from "@/components/BaseLoading.vue"
import MenuCategoryComponent from "@/components/MenuCategory.vue"
import {
  MenuCategory,
  sortCategories
} from "@/model/MenuCategory"
import {
  MenuDish,
  MenuDishesDicttionary,
  sortDishes
} from "@/model/MenuDish"

interface QueryResult {
  loading: boolean
  data: {
    /* eslint-disable camelcase */
    menu_categories: MenuCategory[]
    menu_dishes: MenuDish[]
    /* eslint-enable camelcase */
  }
  error: Error
}

export default Vue.extend({
  components: {
    BaseErrorText,
    BaseLoading,
    MenuCategoryComponent
  },

  data() {
    // keep in sync with methods.resetData
    return {
      categoriesSorted: [] as MenuCategory[],
      /** grouped by menu_category.id, sorted within group */
      dishesDisplay: {} as MenuDishesDicttionary
    }
  },

  methods: {
    onQueryResult({ loading, data, error }: QueryResult): void {
      if (loading) {
        return // handled in template
      }

      if (error) {
        this.resetData()
        return // handled in template
      }

      this.categoriesSorted = sortCategories(data.menu_categories ?? [])
      const dishesGrouped = groupBy(
        data.menu_dishes ?? [],
        d => d.menu_category.id
      )
      this.dishesDisplay = mapValues(dishesGrouped, ds => sortDishes(ds))
    },

    resetData(): void {
      this.categoriesSorted = []
      this.dishesDisplay = {}
    }
  }
})
