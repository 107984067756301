import { render, staticRenderFns } from "./MenuCategory.vue?vue&type=template&id=4312b25e&scoped=true&"
import script from "./MenuCategory.vue?vue&type=script&lang=ts&"
export * from "./MenuCategory.vue?vue&type=script&lang=ts&"
import style0 from "./MenuCategory.vue?vue&type=style&index=0&id=4312b25e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4312b25e",
  null
  
)

export default component.exports